@import "../index.scss";

#reply-container{
    margin: 10px;
}

#votes{
    color: $quaternary-color;
    margin-left: 3px;
    margin-right: 3px;
}

