@import "../index.scss";

.icon-green {
    color: green;
    font-size: 25px;
}

.GameCard{
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover{
        transform: scale(1.05);
    }
}

#under-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

