@import "../../index.scss";

.PostButton {
    transition: background-color .3s;
    background-color: $primary-color;
    color: $clickable-color;
    padding: 2.5px;
    border-radius: 6px;
  }
  
  .PostButton:hover {
    transition: 0.3s ease-in-out;
    background-color: $secondary-hover-color;
  }
  