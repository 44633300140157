@import "../index.scss";

.AchievementCard{
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover{
        transform: scale(1.05);
    }
    
}

.achievementLogo{
    width: 64px;
    height: 64px;
    margin: 5px;
}

.percentage{
margin-top: 10px;
}

.achievementInfo {
    display: flex;
    color: $quaternary-color;
  }
  
#Lock{
  float: right;
    opacity: 60%;
}
