@import "../index.scss";

.navbar {
    background-color: $secondary-color;
}

.navbarlogo{
    font-weight: 500;
    padding-right: 1rem;
    color: $tertiary-color;
}

.pfp{
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    margin-right: 1rem;
    margin-left: 1rem; 

    border-color: $primary-color;
    border-width: 0.1;
    border-style: solid;
}


