@import "../index.scss";

.welcomeHeader{
    color: $tertiary-color;
    text-align: center;
    font-size: 3.5rem;
    
}

.welcomeDescription{
    color: $tertiary-color;
    text-align: center;
    font-size: 1.75rem;
    padding-bottom: 1rem;
    font-weight: 700;
}

@media (max-width: 625px) {
    .navbar-brand{
      font-size: 13px !important;
    }
}

@media (max-width: 420px) {
    .navbar-brand{
      font-size: 10px !important;
    }
}

#container{margin-top: 13%;}
  
#backgroundOverlay{
    background-color: rgba(0,0,0,0.877);
    height: 100vh;
}

#background{
    background: url('/images/home.PNG') no-repeat center center fixed;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

