@import "../index.scss";

.Dashboard{
    margin: 10px;
    padding: 10px;
    border: 3px solid $primary-color;
    border-radius: 10px;
    background-color: $secondary-color;
}

#button-text{
    margin-top: 7px;
}
#text-color{
    color: $tertiary-color;
}

.stats-text{
    opacity: 0.7;
    font-size: 0.8em;
}
.stats-spacing{
    margin-left: 2%;
}

.Circle{
    height: 230px;
    width: 230px;
}

    
