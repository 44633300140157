@import "../index.scss";


#pfp {
  width: 2.8rem;
  margin-left: 10px;
  border-radius: 50%;
  border: 0.1rem solid var(--primary-color);
}
#username{
  font-weight: 495;
}

#date{
  opacity: 0.5;
}
.toast-container {
    background-color: transparent; /* Set the background color of the Toast to transparent */
  }
  
  .toast-container .toast-body {
    color: var(--tertiary-color);
  }

  #content{
    margin-left: 10px;  
}

.badge-tag{
  background-color: $primary-color;
  font-weight: 400;
  padding-left: 9px;
  padding-right: 9px;
  border-radius: 20px;

  &.id1{
    color: $clickable-color;
  }
  &.id2{
    color: $tag1-color;
  }
  &.id3{
   color: $tag2-color;
  }
  &.id4{
   color: $tag3-color;
  }

}

