@import "../index.scss";


h1{
    padding: 10px;
    color: $tertiary-color;
}

h2,h3{
    padding: 10px;
    color: $quaternary-color;
}






