@import "../../index.scss";

.cancel-button{
    transition: background-color .3s;
    background-color: $secondary-color;
    color: $clickable-color;
    border: none;
    padding: 10px;
    border-radius: 12px;

}

.cancel-button:hover {
    transition: 0.3s ease-in-out;
    background-color: $secondary-hover-color;
}

.delete-button{
    transition: background-color .3s;
    background-color: $delete-color;
    color: $quaternary-color;
    border: none;
    padding: 10px;
    border-radius: 12px;
}

.delete-button:hover {
    transition: 0.3s ease-in-out;
    background-color: $delete-hover-color;
}
