@import "../index.scss";

.cardFlex{
    display: flex;
    padding: 15px;
    gap: 15px;
    flex-wrap: wrap; 
    justify-content: center;

}

.loading{
     display: flex;
    align-items: center;
    justify-content: center;
}

.loadingText{
    color: $secondary-color;
    text-align: center;
    font-size: 1.75rem;
    padding-bottom: 1rem;
}