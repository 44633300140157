@import "../../index.scss";

.loginButtonFlex{
  display: flex;
  padding: 15px;
  gap: 15px;
  flex-wrap: wrap; 
  justify-content: center;

}

.loginInnerButtonFlex{
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 30px;

}

button{
  border: none !important;
}

